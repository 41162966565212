/* main css starts from here  */

html {
  height: 100%;
}
body {
  padding: 0;
  margin: 0;
  background-color: #f8f8f8;
  min-height: 100%;
  position: relative;
}

.pad-bot {
  padding-bottom: 50px;
  min-height: 100%;
}
.main-header {
  padding-top: 12px;
}
.main-header a.navbar-brand {
  max-width: 240px;
  margin: 0;
  padding: 0;
}
.main-header .navbar {
  padding: 0;
}
.main-header .round-black {
  padding: 0px 0px;
  background-color: #25addc;
  border-radius: 50px;
  position: relative;
  padding-right: 225px;
}

.main-header .round-black a.nav-link:first-child {
  border-radius: 50px 0px 0px 50px;
}

.main-spin {
  width: 70px;
  height: 70px;

  border-color: #25addc;
  border-right-color: transparent;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff9e;
  z-index: 1111111111111111;
}
.loader.in {
  display: flex;
}
.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 1111111111111;
}

.loader.in.diploma-loader {
  background-color: #ffffff;
}

.main-header .round-black a.nav-link {
  padding: 10px 30px;
  /* margin: 0px 15px; */
  text-transform: uppercase;
  color: #ffffff !important;
  opacity: 1;
  transition: all 0.4s;
  font-weight: 400;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

.main-header .round-black a.nav-link:hover,
.main-header .round-black a.nav-link.active {
  color: #ffffff;
  transition: all 0.4s;
  background-color: #e0876c;
}

.main-header nav.flex-column a.navbar-brand {
  max-width: 300px;
}

.red-welcome {
  background-color: #e0876c;
  position: absolute;
  height: 69px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0px 13px;
  top: -17px;
  right: 40px;
  border-radius: 10px 10px 0px 0px;

  max-width: 190px;
}
.welcome-txt {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.lef-red {
  position: absolute;
  bottom: -23px;
  left: -23px;
}

.rig-red {
  position: absolute;
  bottom: -22px;
  right: -23px;
}
.round-red-btn {
  padding: 10px 28px;
  color: white;
  font-size: 18px;
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
  background: #e38c67;
  display: flex;
  justify-content: center;
  width: fit-content;
  border-radius: 30px;
  text-decoration: none;
  align-items: center;
  transition: all 0.4s;
  border: 0;
}
.round-red-btn:hover {
  transition: all 0.4s;
  background: #00a9e0;
  color: white;
  text-decoration: none;
}

footer {
  padding: 13px 0px;
  background-color: #00a9e0;
  color: #ffffff !important;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  float: left;
  position: absolute;
}

footer a {
  color: #ffffff !important;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
}

.hmbrgr {
  position: relative;
  display: inline-block;
  height: 37px;
  width: 37px;
  border: 0;
  outline: none !important;
  box-shadow: none !important;
  margin-top: 8px;
}
.hmbrgr span {
  position: absolute;
  left: 0;
  width: 100%;
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 5px;
  border-radius: 3px;
  background-color: #25addc;
  transition-duration: 200ms;
}
.hmbrgr span:nth-child(1) {
  top: 0px;
}
.hmbrgr span:nth-child(2) {
  top: 12px;
}
.hmbrgr span:nth-child(3) {
  top: 24px;
}

.hmbrgr[aria-expanded="true"] span:nth-child(1) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);

  top: 14px !important;
}

.hmbrgr[aria-expanded="true"] span:nth-child(2) {
  zoom: 1;
  filter: alpha(opacity=0);
  -webkit-opacity: 0;
  -moz-opacity: 0;
  opacity: 0;
}

.hmbrgr[aria-expanded="true"] span:nth-child(3) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);

  top: 15px !important;
}

.svg-icon {
  width: 60px;
  fill: #e38c67;
  height: 60px;
}
.main-ban {
  background-image: url("../../../../public/images/index.jpg");
  background-size: cover;
  padding-bottom: 130px;
  float: left;
  width: 100%;
}
.sliding-bg {
  height: 500px;
  background-image: url("../../../../public/images/slide-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: auto;
  float: none;
}

.notfound-bg {
  background-image: url("../../../../public/images/err.png") !important;
}

.slide-up .container {
  max-width: 1108px;
  position: relative;
}

section.slide-up {
  margin-top: -290px;
  margin-bottom: 44px;
}

.slides-here {
  position: relative;
}
.back-slid::after {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
}

.text-contain,
.text-contain .row {
  height: 100%;
}
.text-contain .inc-z {
  z-index: 111;
  height: 100%;
}

.slide-heading {
  position: absolute;
  left: 0;
  right: 0;
  top: -70px;
  width: 100%;
  text-transform: uppercase;
  text-align: center;
  color: #e38c67;
  font-size: 40px;
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  margin: 0;
  z-index: 11;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.slide-heading span {
  color: #000000;
  font-weight: 700;
}
.circle-list {
  padding: 0;
  margin: 0;
  list-style: none;
  padding-right: 40px;
}

.circle-list LI {
  color: #000000;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  padding-left: 25px;
  position: relative;
  margin-bottom: 15px;
  line-height: 1.8;
}

.circle-list LI:last-child {
  margin-bottom: 0;
}

.circle-list LI::before {
  position: absolute;
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 3px solid #e38c67;
  content: "";
}

.form-box {
  padding: 30px;
  background-color: white;
  box-shadow: 0px 0px 14px 0px #92929254;
  margin-bottom: 30px;
}

h4.prf-hed {
  color: #00a9e0;
  font-size: 30px;
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  margin: 0;
}

.form-filds .form-floating {
  margin-top: 20px;
}
.form-filds .form-floating > label {
  font-weight: 400;
  font-size: 16px;
  font-family: "Sarabun", sans-serif;
}

.form-filds .form-floating > label > span {
  color: #e38c67;
}
.form-filds .form-control:focus,
.form-filds .form-select:focus {
  box-shadow: none;
  border-color: #e38c67;
}

.store-details h6 {
  margin: 0;
  margin-top: 30px;
  font-weight: 700;
  font-size: 16px;
  font-family: "Sarabun", sans-serif;
}

.store-details h6 span {
  font-weight: 400;
  display: block;
}

.form-box .round-red-btn {
  padding: 8px 50px;
  border: 0;
}

.save-acc,
.edit-acc {
  display: flex;
}

.Toastify__toast-container {
  width: auto !important;
}

.form-filds .form-floating.is-danger > label > span {
  color: red !important;
}
select.form-select.is-danger,
input.form-control.is-danger {
  border-color: red !important;
  color: red !important;
}

.round-red-btn.save-acc.shown,
.edit-acc.shown {
  display: flex;
}

ul.timeline {
  list-style-type: none;
  position: relative;
  padding-left: 12px;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 9px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 0px 0;
  padding-left: 20px;
}
.single-li {
  margin-bottom: 0;
}
.single-li.timeline > li {
  padding-bottom: 30px;
}
.single-li.timeline > li .form-box {
  margin-bottom: 0px;
}

ul.timeline > li:before {
  content: " ";
  background: #e38c67;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #ffffff;
  left: 0px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
.round-with-bar {
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.round-with-bar::after {
  content: "";
  width: 215px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #e38c67;
  height: 100%;
  position: absolute;
  z-index: -1;
  margin: 0 auto;
}

.round-bor {
  width: 327px;
  height: 327px;
  object-fit: contain;
  border-radius: 50%;
  border: 9px solid white;
  overflow: hidden;
}

.round-with-bar .round-bor img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.8;
}
.back-slid {
  height: 568px;
  background-image: url("../../../../public/images/slide-1.jpg");
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.faq-vid {
  height: 610px;
  object-fit: cover;
}

.form-floating.is-danger .form-control {
  color: red;
  border-color: red;
}

.form-floating.is-danger > .form-select {
  color: red;
  border-color: red;
}

.form-floating.is-danger > label {
  color: red;
}
.form-floating.is-danger p.help.is-danger {
  color: red;
  font-size: 13px;
  font-weight: 400;
  font-family: "Sarabun", sans-serif;
}

p.help.is-danger {
  color: red;
  font-size: 13px;
  font-weight: 400;
  font-family: "Sarabun", sans-serif;
  margin-bottom: 0;
}

.tab-content .tab-pane {
  display: none;
}
.tab-content .active {
  display: block;
}
#button {
  display: flex;
  background-color: #e38c67;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  position: fixed;
  bottom: 20px;
  right: 20px;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: -1000;
  align-items: center;
  justify-content: center;
}
#button img {
  width: 20px;
}
.error-404 {
  min-height: 100vh;
}
#button:hover {
  cursor: pointer;
  background-color: #00a9e0;
}
#button:active {
  background-color: #000;
}
#button.show {
  opacity: 1;
  visibility: visible;
  z-index: 100011;
}
.select-leading {
  position: relative;
}
.select-leading span.spinner-border.spinner-border-sm {
  position: absolute;
  right: 12%;
  z-index: 1;
  top: 36%;
}
.prfil-set button:disabled,
.prfil-set [type="button"]:disabled,
.prfil-set [type="reset"]:disabled,
.prfil-set [type="submit"]:disabled {
  opacity: 0.4;
  pointer-events: none;
  cursor: not-allowed;
}

.prfil-set button:not(:disabled) .spinner-border-sm,
.prfil-set [type="button"]:not(:disabled) .spinner-border-sm,
.prfil-set [type="reset"]:not(:disabled) .spinner-border-sm,
.prfil-set [type="submit"]:not(:disabled) .spinner-border-sm {
  display: none;
}
.prfil-set button:disabled,
.prfil-set [type="button"]:disabled .spinner-border-sm,
.prfil-set [type="reset"]:disabled .spinner-border-sm,
.prfil-set [type="submit"]:disabled .spinner-border-sm {
  display: inline-block;
  margin-right: 5px;
}

.trianles {
  position: relative;
  font-size: 15px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-transform: capitalize;
}

.les-tp h6 {
  margin-top: 15px;
}

.astric-req {
  font-weight: 400;
  margin: 0;
}
.astric-req span {
  font-weight: 700;
}

@media (min-height: 960px) {
  .back-slid,
  .faq-vid {
    height: 70vh;
  }

  section.slide-up {
    margin-top: -27vh;
  }
  .make-center {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

@media (min-height: 1200px) {
  .back-slid,
  .faq-vid {
    height: 75vh;
  }

  section.slide-up {
    margin-top: -30vh;
  }
}
@media (min-width: 1200px) and (max-width: 1800px) {
  .main-ban {
    background-position-x: center;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-ban {
    background-position-x: center;
  }
  .sliding-bg {
    height: 460px;
  }

  .main-header .round-black a.nav-link {
    font-size: 15px;
    padding: 10px 27px;
  }

  .main-ban .container {
    max-width: 1108px;
  }

  .faq-vid {
    height: 595px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .main-ban {
    background-position-x: center;
  }

  .pad-bot {
    padding-bottom: 70px;
  }
  .main-header {
    position: relative;
  }
  .main-header .navbar {
    position: static;
  }

  body {
    overflow-x: hidden;
  }
  .main-header .navbar-collapse {
    height: 100%;
    /*    overflow-x: hidden;
  overflow-y: hidden;*/
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    margin-top: 0px;
    transform: translateX(300px);
    transition: transform 250ms ease-in-out;
    background: #25addc;
    z-index: 1111111;
    display: block;
  }

  .main-header .navbar-collapse.show {
    transform: translateX(0);
  }

  .main-header .navbar-collapse:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 100vw;
    background: #30abd5;
    content: "";
    transform: skewX(-28deg) translateX(100%);
    transform-origin: bottom left;
    will-change: transform;
    z-index: 0;
    transition: all 250ms ease-in-out;
  }

  .main-header .navbar-collapse.show:before {
    transform: skewX(-28deg) translateX(0);
    transition: all 250ms ease-in-out;
  }

  .main-header .navbar-collapse.show:after {
    position: fixed;
    /* left: 0; */
    right: 0;
    top: 0;
    width: 100vw;
    bottom: 0;
    background: #dd81709c;
    content: "";
    z-index: -1;
  }

  .main-header .round-black {
    padding: 0;
    border-radius: 0px;
    background: transparent;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .red-welcome {
    background-color: #e0876c;
    position: absolute;
    height: auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px 15px;
    top: 20px;
    right: auto;
    left: 5px;
    border-radius: 0;
  }

  .main-header .round-black a.nav-link {
    padding: 0px;
    text-align: center;
    margin-bottom: 25px;
    font-size: 20px;
  }

  .main-header .round-black a.nav-link:hover,
  .main-header .round-black a.nav-link.active {
    background-color: transparent;
    color: #e0876c !important;
    font-weight: 700;
  }

  .main-header .round-black a.nav-link:first-child {
    border-radius: 00px 0px 0px 00px;
  }

  .main-header .round-black a.nav-link {
    transform: translateX(1000%);
    transition: color 0.15s, transform 0.5s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .main-header .navbar-collapse.show a.nav-link {
    transform: translateX(0);
  }
  .main-header .navbar-collapse.show a:nth-child(1) {
    transition-delay: 100ms;
  }
  .main-header .navbar-collapse.show a:nth-child(2) {
    transition-delay: 150ms;
  }
  .main-header .navbar-collapse.show a:nth-child(3) {
    transition-delay: 200ms;
  }

  .main-header .navbar-collapse.show a:nth-child(4) {
    transition-delay: 250ms;
  }

  .main-header .hmbrgr[aria-expanded="true"] {
    position: fixed;
    right: 12px;
    z-index: 111111111111111111;
  }

  .main-header .hmbrgr[aria-expanded="true"] span {
    background-color: #dd8170;
  }

  .main-ban .container {
    max-width: 1050px;
  }

  .back-slid {
    height: auto;
  }
  .back-slid {
    height: auto;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .round-with-bar::after {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #e38c67;
    width: auto;
    position: absolute;
    z-index: -1;
    margin: auto;
    height: 170px;
  }
  section.slide-up {
    margin-top: -350px;
  }

  .slide-heading {
    font-size: 32px;
    top: -60px;
  }
  footer,
  .col-lg-3.text-end {
    text-align: center !important;
  }
  span.gen-txt {
    font-size: 14px;
  }
  .trianles img {
    display: none;
  }
  .faq-vid {
    height: auto;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .main-ban {
    background-position-x: center;
  }
  .circle-list LI::before {
    position: absolute;
    left: 0;
    top: 1px;
  }

  .pad-bot {
    padding-bottom: 0px;
  }

  footer {
    position: static;
  }
  body {
    padding-bottom: 0px;
  }
  .main-header {
    position: relative;
  }
  .main-header .navbar {
    position: static;
  }
  body {
    overflow-x: hidden;
  }
  .main-header .navbar-collapse {
    height: 100%;
    /*    overflow-x: hidden;
  overflow-y: hidden;*/
    position: fixed;
    right: 0;
    top: 0;
    width: 188px;
    margin-top: 0px;
    transform: translateX(300px);
    transition: transform 250ms ease-in-out;
    background: #25addc;
    z-index: 1111111;
    display: block;
  }

  .main-header .navbar-collapse.show {
    transform: translateX(0);
  }

  .main-header .navbar-collapse:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    width: 100vw;
    background: #30abd5;
    content: "";
    transform: skewX(-12deg) translateX(100%);
    transform-origin: bottom left;
    will-change: transform;
    transition: all 250ms ease-in-out;
  }

  .main-header .navbar-collapse.show:before {
    transform: skewX(-12deg) translateX(0);
    transition: all 250ms ease-in-out;
  }
  .main-header .navbar-collapse.show:after {
    position: fixed;
    /* left: 0; */
    right: 0;
    top: 0;
    width: 100vw;
    bottom: 0;
    background: #dd81709c;
    content: "";
    z-index: -1;
  }

  .main-header .round-black {
    padding: 0;
    border-radius: 0px;
    background: transparent;
    height: 100%;
    display: flex;
    align-items: center;
  }
  .trianles img {
    display: none;
  }
  .red-welcome {
    background-color: #e0876c;
    position: absolute;
    height: auto;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 10px 15px;
    top: 65px;
    right: auto;
    left: 0px;
    border-radius: 0;
  }

  .main-header .round-black a.nav-link {
    padding: 0px;
    text-align: center;
    margin-bottom: 25px;
    font-size: 20px;
  }

  .main-header .round-black a.nav-link:hover,
  .main-header .round-black a.nav-link.active {
    background-color: transparent;
    color: #e0876c !important;
    font-weight: 700;
  }

  .main-header .round-black a.nav-link:first-child {
    border-radius: 00px 0px 0px 00px;
  }

  .main-header .round-black a.nav-link {
    transform: translateX(1000%);
    transition: color 0.15s, transform 0.5s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }

  .main-header .navbar-collapse.show a.nav-link {
    transform: translateX(0);
  }
  .main-header .navbar-collapse.show a:nth-child(1) {
    transition-delay: 100ms;
  }
  .main-header .navbar-collapse.show a:nth-child(2) {
    transition-delay: 150ms;
  }
  .main-header .navbar-collapse.show a:nth-child(3) {
    transition-delay: 200ms;
  }

  .main-header .navbar-collapse.show a:nth-child(4) {
    transition-delay: 250ms;
  }

  .main-header .hmbrgr[aria-expanded="true"] {
    position: fixed;
    right: 12px;
    z-index: 111111111111111111;
  }

  .main-header .hmbrgr[aria-expanded="true"] span {
    background-color: #dd8170;
  }

  .main-ban .container {
    max-width: 1050px;
  }

  span.gen-txt {
    font-size: 14px;
  }

  footer,
  .col-lg-3.text-end {
    text-align: center !important;
  }

  .round-red-btn {
    padding: 6px 20px;
    color: white;
    font-size: 16px;
  }

  .circle-list LI {
    color: #000000;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    padding-left: 25px;
    position: relative;
    margin-bottom: 10px;
    line-height: 1.3;
  }

  .round-with-bar::after {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #e38c67;
    width: auto;
    position: absolute;
    z-index: -1;
    margin: auto;
    height: 115px;
  }
  .round-with-bar {
    margin-top: 30px;
  }
  .round-bor {
    width: 260px;
    height: 260px;
  }

  section.slide-up {
    margin-top: -350px;
  }
  .circle-list {
    padding-right: 0px;
  }

  .faq-vid {
    height: auto;
  }

  h4.prf-hed {
    color: #00a9e0;
    font-size: 22px;
  }

  .slide-heading span {
    color: #000000;
    font-weight: 700;
    display: block;
  }
  .form-box .round-red-btn {
    padding: 4px 24px;
    border: 0;
    width: 100%;
    margin-top: 10px;
  }

  .form-box {
    padding: 20px;
    background-color: white;
    box-shadow: 0px 0px 14px 0px #92929254;
    margin-bottom: 20px;
  }
  .store-details h6 {
    margin: 0;
    margin-top: 15px;
  }
  .back-slid {
    height: auto;
    padding-top: 70px;
    padding-bottom: 40px;
  }
  .back-slid.slide-secnd.h-auto {
    height: auto !important;
    padding-top: 70px !important;
    padding-bottom: 40px !important;
  }

  .slide-heading {
    font-size: 26px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

button.btn.btn-primary.px-4.d-block.back-blue.show {
  margin-left: 15px;
  width: 18%;
  padding-right: -1rem !important;
  padding-left: 1rem !important;
  height: 25px;
  padding-top: 1px;
}