/* second login css start here  */
.login-2 .white-box .input-group img {
  width: 18px;
}
.login-2 {
  min-height: 100vh;
  position: relative;
  background-image: url("../../../../public/images/login2-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  /*padding-bottom: 140px;*/
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  font-family: "Sarabun", sans-serif;
}
.login-2 .container {
  max-width: 1660px;
}

.login-2 .logo {
  margin-top: 65px;
}

.login-2 h2 {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  color: #000000;
  font-size: 50px;
  margin-top: 85px;
  margin-bottom: 25px;
}
.login-2 h2 span {
  color: #000000;
  font-weight: 700;
  font-size: 70px;
  text-transform: uppercase;
  margin-right: 14px;
}

.login-2 p {
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
  color: #000000;
  font-size: 14px;
  padding-left: 13px;
  margin: 0;
  margin-bottom: 50px;
}

.login-2 .white-box {
  background-color: #ffffff;
  height: 427px;
  width: 430px;
  margin-top: 80px;
  padding-top: 90px;
  padding-bottom: 100px;
  padding-left: 40px;
  padding-right: 40px;
  transform: rotate(45deg);
}
.login-2 .white-box .rever-trans {
  transform: rotate(-45deg);
}

.login-2 .white-box form {
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.login-2 .white-box .input-group {
  margin-bottom: 18px;
}

.login-2 .white-box .input-group .input-group-text {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #7a6869;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
}

.login-2 .white-box .form-control {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #7a6869;
  border-radius: 0;
  font-size: 14px;
  color: #474646;
  font-weight: 400;
}
.login-2 .white-box .form-control:focus {
  box-shadow: none;
  outline: none;
}

.login-2 .white-box .input-group.is-danger .form-control {
  color: red;
  border-bottom-color: red;
}

.login-2
  .white-box
  .input-group.is-danger
  .form-control::-webkit-input-placeholder {
  /* Edge */
  color: red;
}
.login-2 .white-box .input-group.is-danger .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}
.login-2 .white-box .input-group.is-danger .form-control::placeholder {
  color: red;
}

.login-2 .white-box .input-group.is-danger .input-group-text {
  border-bottom-color: red;
}

.login-2 .white-box .input-group.is-danger .input-group-text img {
  filter: invert(48%) sepia(86%) saturate(7443%) hue-rotate(350deg)
    brightness(90%) contrast(134%);
}

.login-2 .white-box input[type="submit"] {
  width: 100%;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  height: 45px;
  background: #b51019;
  border: 0;
  outline: none;
  box-shadow: none;
  margin-top: 17px;
  margin-bottom: 30px;
  transition: all 0.4s;
}
.login-2 .white-box input[type="submit"]:hover {
  background: #630006;
  transition: all 0.4s;
}

.login-2 .white-box ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.login-2 .white-box ul li {
  display: block;
  align-items: center;
  color: #474646;
  font-weight: 400;
  font-size: 14px;
  margin-top: 3px;
  text-align: center;
}
.login-2 .white-box ul li a {
  color: #b51019;
  font-weight: 700;
  text-decoration: underline;
}

.login-2 .copyright {
  color: #000000;
  font-size: 16px;
  max-width: 500px;
  font-weight: 400;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 0 auto;
  border-radius: 20px;
  position: absolute;
  left: 0;
  right: 0;
  /*bottom: 55px;*/
  bottom: 10px;
}

.login-2 .cap-img {
  margin: 0 auto;
  display: block;
}
.login-2 .video-holder {
  position: fixed;
  height: auto;
  width: 20%;
  /* width: 400px; */
  overflow: hidden;
  bottom: 10px;
  right: 15px;
  z-index: 11;
}

/* responsiveness  */
@media (min-width: 1600px) and (max-width: 1799px) {
  .login-2 .white-box {
    transform: rotate(45deg) translateX(33px) translateY(-89px);
  }
}
@media (min-width: 1200px) and (max-width: 1599px) {
  .login-2 .white-box {
    transform: rotate(45deg) translateX(33px) translateY(-89px);
  }
  .login-2 h2 {
    font-size: 40px;
  }
  .login-2 h2 span {
    font-size: 60px;
  }

  .login-2 .cap-img {
    display: block;
    max-width: 74%;
    margin: 0;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .login-2 .white-box {
    transform: rotate(45deg) translateX(0px) translateY(-89px);
    background-color: #ffffff;
    height: 327px;
    width: 330px;
    margin-top: 80px;
    padding-top: 65px;
    padding-bottom: 65px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .login-2 .white-box .input-group {
    margin-bottom: 10px;
  }
  .login-2 h2 {
    font-size: 34px;
  }
  .login-2 .white-box ul li {
    font-size: 13px;
  }
  .login-2 .white-box input[type="submit"] {
    width: 100%;
    color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    height: 30px;
    background: #b51019;
    border: 0;
    outline: none;
    box-shadow: none;
    margin-top: 10px;
    margin-bottom: 10px;
    transition: all 0.4s;
  }

  .login-2 h2 span {
    font-size: 53px;
  }

  .login-2 .cap-img {
    display: block;
    max-width: 74%;
    margin: 0;
    margin-left: auto;
  }

  .login-2 .copyright {
    font-size: 14px;
    max-width: 440px;
  }
  .login-2 .video-holder {
    width: 30%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .login-2 .logo {
    margin: 0 auto;
    margin-top: 30px;
    display: block;
  }

  .login-2 h2 {
    font-size: 40px;
    margin-top: 0 !important;
    margin-bottom: 5px;
  }
  .login-2 h2 span {
    font-size: 60px;
  }

  .login-2 p {
    padding-left: 5px;
  }
  .login-2 .white-box {
    margin: 70px auto;
  }
  .login-2 .cap-img {
    margin-top: 30px;
  }

  .login-2 {
    overflow: visible !important;
    height: auto !important;
  }
  .login-2 .video-holder {
    position: static;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 15px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .login-2 .logo {
    margin: 0 auto;
    margin-top: 30px;
    display: block;
  }

  .login-2 h2 {
    font-size: 32px;
    margin-top: 0 !important;
    margin-bottom: 5px;
  }
  .login-2 h2 span {
    font-size: 50px;
    display: block;
  }

  .login-2 .white-box {
    background-color: #ffffffb0;
    height: 327px !important;
    margin: 45px auto;
    padding: 60px 20px !important;
    width: 330px !important;
    -webkit-transform: rotate(45deg) !important;
    transform: rotate(45deg) !important;
  }
  .login-2 .white-box .rever-trans {
    padding: 0px !important;
  }
  .login-2 {
    overflow-x: hidden;
    padding-bottom: 90px;
  }
  .login-2 .cap-img {
    margin-top: 30px;
  }

  .login-2 .white-box .input-group {
    margin-bottom: 10px;
  }
  .login-2 p {
    margin-bottom: 30px;
    padding-left: 0;
  }
  .login-2 .white-box input[type="submit"] {
    width: 100%;
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
    height: 38px;
    background: #b51019;
    border: 0;
    outline: none;
    box-shadow: none;
    margin-top: 10px;
    margin-bottom: 20px;
    transition: all 0.4s;
  }

  .login-2 .white-box ul li {
    font-size: 13px;
  }

  .login-2 .copyright {
    color: #000000;
    font-size: 13px !important;
    max-width: 280px;
    font-weight: 400;
    height: 47px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    margin: 0 auto;
    max-width: 280px !important;
    border-radius: 43px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    text-align: center;
  }

  .login-2 {
    overflow: hidden !important;
    height: auto !important;
  }
  .login-2 .video-holder {
    position: static;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 15px;
  }
}

@media (min-height: 700px) and (max-height: 900px) {
  .login-2 .logo {
    margin-top: 3vh;
  }

  .login-2 h2 {
    margin-top: 5vh;
    margin-bottom: 5px;
  }
  .login-2 {
    overflow: hidden;
    height: 100vh;
  }
}

@media (min-height: 600px) and (max-height: 699px) {
  .login-2 .logo {
    margin-top: 1vh;
    width: 60%;
  }

  .login-2 h2 span {
    font-size: 40px;
  }

  .login-2 h2 {
    margin-top: 3vh;
    margin-bottom: 5px;
    font-size: 30px;
  }

  .login-2 .white-box .rever-trans {
    transform: rotate(-45deg);
    padding: 30px;
  }

  .login-2 .white-box {
    height: 377px;
    width: 370px;
    display: flex;
    margin-top: 55px;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    transform: rotate(45deg) translateX(33px) translateY(-89px);
    align-items: center;
    justify-content: center;
  }

  .login-2 .copyright {
    font-size: 14px;
    max-width: 447px;
    height: 35px;
  }

  .login-2 {
    overflow: hidden;
    height: 100vh;
  }
}

/* login page css end here  */
