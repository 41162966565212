.rzl-tabl .form-select,
.rzl-tabl input.form-control {
  border-color: #25addc;
}
.rzl-tabl .form-select:focus,
.rzl-tabl input.form-control:focus {
  box-shadow: none;
  border-color: #e38c67;
}
.rzl-tabl .btn-outline-secondary:focus,
.rzl-tabl .btn-outline-secondary:hover,
.rzl-tabl .btn-outline-secondary.sebmit-dat {
  color: #ffffff;
  border-color: #25addc;
  background-color: #25addc;
}
.rzl-tabl .btn-outline-secondary,
.rzl-tabl .btn-outline-secondary.sebmit-dat:hover,
.rzl-tabl .btn-outline-secondary.sebmit-dat:focus {
  color: #ffffff;
  border-color: #e38c67;
  background-color: #e38c67;
}

.rzl-tabl .btn-outline-secondary:focus,
.rzl-tabl .btn-outline-secondary:hover {
  box-shadow: none;
  outline: none;
  color: white;
}

.form-box.rzl-tabl thead th {
  position: relative;
  padding-right: 26px !important;
}

.form-box.rzl-tabl thead th::after {
  content: "";
  background-image: url(../../../../public/images/sort-ascending.png);
  height: 18px;
  width: 18px;
  float: right;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 6px;
  top: 18px;
  opacity: 0.5;
}
.form-box.rzl-tabl thead th.ascending::after {
  content: "";
  background-image: url(../../../../public/images/sort-ascending.png);
  height: 18px;
  width: 18px;
  float: right;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 6px;
  top: 18px;
  opacity: 1;
}
.form-box.rzl-tabl thead th.descending::after {
  content: "";
  background-image: url(../../../../public/images/sort-descending.png);
  height: 18px;
  width: 18px;
  float: right;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 6px;
  top: 18px;
  opacity: 1;
}

.form-box.rzl-tabl .pagination {
  justify-content: center;
}
.form-box.rzl-tabl .pagination .page-link {
  color: #fff;
  border: 1px solid #25addc;
  background-color: #25addc;
}
.form-box.rzl-tabl .pagination .page-link:focus {
  box-shadow: none;
}

.form-box.rzl-tabl .page-item:first-child .page-link {
  border-top-left-radius: 1000px;
  border-bottom-left-radius: 1000px;
}
.form-box.rzl-tabl .page-item:last-child .page-link {
  border-top-right-radius: 1000px;
  border-bottom-right-radius: 1000px;
}

.form-box.rzl-tabl .pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #e0876c;
  border-color: #e0876c;
}
.form-box.rzl-tabl .pagination .page-item.disabled .page-link {
  opacity: 0.6;
}

.form-box.rzl-tabl thead {
  background-color: #00a9e0;
  color: #fff;
}

.form-box.rzl-tabl thead th {
  padding: 15px 13px;
  cursor: pointer;
}

.form-box.rzl-tabl caption {
  color: #000000;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.form-box.rzl-tabl .pasad {
  color: #278858;
  font-weight: 600;
}

.form-box.rzl-tabl .faled {
  color: #e38c67;
  font-weight: 600;
}
.form-box.rzl-tabl tbody tr:nth-child(even) {
  background: #25addc0d;
}

.form-box.rzl-tabl tbody tr td {
  padding: 15px 13px;
  color: #000000;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.rzl-tabl h3 {
  font-family: "Oswald", sans-serif;
  color: #000;
  font-size: 36px;
  margin-bottom: 0px;
  font-weight: 300;
}
@media (min-width: 768px) and (max-width: 992px) {
  .rzl-tabl h3 {
    font-size: 28px;
  }

  .form-box.rzl-tabl caption {
    font-size: 14px;
  }

  .rzl-tabl .form-select,
  .rzl-tabl .input-group,
  .rzl-tabl input[type="date" i] {
    margin-bottom: 15px;
  }

  .rzl-tabl .btn-outline-secondary {
    width: 100%;
  }

  .rzl-tabl .btn-outline-secondary.sebmit-dat {
    width: auto;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .form-box.rzl-tabl thead th {
    vertical-align: middle;
  }

  .form-box.rzl-tabl table.table.caption-top.align-middle.table-borderless {
    min-width: 700px;
  }
  .rzl-tabl .form-select,
  .rzl-tabl .input-group,
  .rzl-tabl input[type="date" i] {
    margin-bottom: 15px;
  }

  .rzl-tabl .btn-outline-secondary {
    width: 100%;
  }

  .rzl-tabl .btn-outline-secondary.sebmit-dat {
    width: auto;
  }
  .form-box.rzl-tabl .pagination {
    justify-content: inherit;
  }
  .table-responsive {
    white-space: nowrap;
  }
}
