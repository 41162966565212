.reset-reg {
  background: #00a9e0 !important;
}

.reset-reg:hover {
  background: #e38c67 !important;
}
ul.timeline > li .circle-list {
  margin-top: 10px;
}
ul.timeline > li .circle-list li {
  font-size: 14px;
  margin-bottom: 5px;
  padding-left: 22px;
  line-height: 1.6;
}
ul.timeline > li .circle-list LI::before {
  width: 13px;
  height: 13px;
  border: 2px solid #e38c67;
}
.chek-term p.help.is-danger {
  color: red;
  font-size: 13px;
  font-weight: 400;
  font-family: "Sarabun", sans-serif;
  margin-top: -15px;
}
.chek-term label.radio-custom-label.is-danger,
.chek-term label.radio-custom-label.is-danger a {
  color: red;
}

.chek-term {
  margin-top: 15px;
}
.chek-term a {
  font-weight: 500;
  color: black;
  text-decoration: none;
}

.chek-term a[target="_blank"] {
  color: #e38c67;
}
.alred-memb {
  margin-left: AUTO;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  font-family: "Sarabun", sans-serif;
}
.alred-memb a.round-red-btn {
  margin-left: 15px;
  padding: 8px 35px;
  font-size: 16px;
}
.modal-body .form-box {
  padding: 0px 10px;
  background-color: white;
  box-shadow: none;
  margin-bottom: 0;
}

.css-t3ipsp-control {
  border: 1px solid #e38c67 !important;
  box-shadow: none !important;
}
.error-select-search .css-13cymwt-control,
.error-select-search .css-t3ipsp-control {
  border: 1px solid red !important;
  box-shadow: none !important;
}
.css-13cymwt-control,
.css-t3ipsp-control {
  /* height: 58px; */
  margin-top: 4px;
  padding-top: 18px;
  padding-bottom: 2px;
  padding-left: 0px;
}
.css-1fdsijx-ValueContainer {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.css-1dimb5e-singleValue {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.css-1jqq78o-placeholder {
  color: #212529 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-floating.is-danger .css-1jqq78o-placeholder {
  color: #f44336 !important;
}

.with-select {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

section.slide-up.registerSide {
  margin-top: -262px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .alred-memb {
    width: 100%;
    margin-top: -18px;
    justify-content: flex-end;
    font-size: 12px;
  }

  .alred-memb a.round-red-btn {
    margin-left: 5px;
    padding: 5px 20px;
    font-size: 13px;
  }
}
