.sliding-menu {
  height: 100%;
  width: 70px;
  position: absolute;
  top: 0;
  left: -26px;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 1012;
}

.sliding-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 5px solid #d7d7d7;
  box-shadow: 0px 0px 14px 0px #92929254;
  background-color: white;
  text-align: center;
  border-radius: 50px;
}

.sliding-menu ul li a {
  padding: 15px 0px;
  display: block;
  border-bottom: 1px solid #d7d7d7;
  color: #000000;
  font-size: 12px;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  transition: all 0.4s;
  line-height: 1.1;
}

.sliding-menu ul li a:hover {
  background: #00a9e0;
  color: white;
  transition: all 0.4s;
}

.sliding-menu ul li:first-child a {
  border-radius: 50px 50px 0px 0px;
}

/* .sliding-menu ul li:last-child a {
  border-bottom: 0;
  border-radius: 0px 0px 50px 50px;
} */

.sliding-menu ul li a img {
  width: 35px;
  transition: all 0.4s;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.sliding-menu ul li a .svg-icon {
  width: 35px;
  fill: #e38c67;
  height: 35px;
  transition: all 0.4s;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.sliding-menu ul li a:hover .svg-icon {
  fill: #fff;
  transition: all 0.4s;
}

.sliding-menu ul li a:hover img {
  transition: all 0.4s;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
    brightness(1003%) contrast(103%);
}

.sliding-menu ul li button {
  padding: 15px 0px;
  display: block;
  border: 0;
  background: transparent;
  border-bottom: 1px solid #d7d7d7;
  color: #000000;
  font-size: 12px;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  transition: all 0.4s;
  line-height: 1.1;
  width: 100%;
}

.sliding-menu ul li button:hover {
  background: #00a9e0;
  color: white;
  transition: all 0.4s;
}

.sliding-menu ul li:last-child button {
  border-bottom: 0;
  border-radius: 0px 0px 50px 50px;
}

.sliding-menu ul li button img {
  width: 35px;
  transition: all 0.4s;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.sliding-menu ul li button .svg-icon {
  width: 35px;
  fill: #e38c67;
  height: 35px;
  transition: all 0.4s;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}

.sliding-menu ul li button:hover .svg-icon {
  fill: #fff;
  transition: all 0.4s;
}

.sliding-menu ul li button:hover img {
  transition: all 0.4s;
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
    brightness(1003%) contrast(103%);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sliding-menu {
    height: 100%;
    width: 60px;
    position: absolute;
    top: 0;
    left: 1px;
  }

  .sliding-menu ul li a {
    font-size: 11px;
    padding: 10px 0px;
  }

  .sliding-menu ul li a .svg-icon {
    width: 25px;
    fill: #e38c67;
    height: 35px;
    transition: all 0.4s;
    display: block;
    margin: 0 auto;
    margin-bottom: 3px;
  }
  .sliding-menu ul li button {
    font-size: 11px;
    padding: 10px 0px;
  }

  .sliding-menu ul li button .svg-icon {
    width: 25px;
    fill: #e38c67;
    height: 35px;
    transition: all 0.4s;
    display: block;
    margin: 0 auto;
    margin-bottom: 3px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .sliding-menu {
    left: 5px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .sliding-menu {
    left: 0px;
    right: 0px;
    width: 100%;
    height: 55px;
    top: -5px;
    bottom: auto;
  }

  .sliding-menu ul {
    width: 100%;
  }

  .sliding-menu ul li {
    width: 25%;
    float: left;
  }

  .sliding-menu ul li a {
    padding: 5px 0px;
    border-bottom: 0px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    height: 100%;
    font-size: 10px;
  }

  .sliding-menu ul li a .svg-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  }

  .sliding-menu ul li:first-child a {
    border-radius: 50px 0px 0px 50px;
  }
  .sliding-menu ul li button {
    padding: 5px 0px;
    border-bottom: 0px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    height: 100%;
    font-size: 10px;
  }

  .sliding-menu ul li button .svg-icon {
    width: 20px;
    height: 20px;
    margin-bottom: 3px;
  }

  .sliding-menu ul li:last-child button {
    border-right: 0px solid #d7d7d7;
    border-radius: 0px 50px 50px 0px;
  }

  .sliding-menu ul li:first-child a {
    border-radius: 50px 0px 0px 50px;
  }
}
