.bg-grey {
  position: relative;
  float: left;
  width: 100%;
  background: url(../../../../../public/images/WEL-SHP.png) no-repeat #f8f8f8;
  background-position: center bottom;
}

.bg-grey .container {
  max-width: 1050px;
}

.move-up {
  height: 210px;
  margin-top: -115px;
  background-color: white;
  box-shadow: 0px 0px 14px #92929254;
  border-radius: 10px;
  width: calc(100% - 120px);
  position: relative;
}

.move-up ul {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
}
.move-up ul li:first-child {
  border-right: 1px solid #cccccc;
}
.move-up ul li {
  display: inline-flex;
  width: 49.76%;
  text-align: center;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  vertical-align: top;
  font-family: "Open Sans", sans-serif;
}
.move-up ul li img {
  width: 50px;
  display: block;
  margin: 0 auto;
}

.move-up ul li .round-red-btn {
  margin: 0 auto;
  margin-top: 20px;
  padding: 7px 28px;
}
.move-up ul li:first-child .round-red-btn {
  pointer-events: none;
}
.move-up ul li h4 {
  font-size: 24px;
  color: #000000;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  margin-top: 7px;
  margin-bottom: 6px;
}
.move-up ul li span.remaining {
  color: #23a3d0;
  font-weight: 600;
}

.edu-cen {
  position: absolute;
  height: 75px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  color: #ffffff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -170px;
  bottom: 66px;
  background-color: #00a9e0;
  width: 210px;
  margin: 0;
  border-radius: 10px;
  transform: rotate(90deg);
}

/* leader masood  */
.px-app,
.px-app *,
.px-app *::before,
.px-app *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}
.px-continer {
  width: 100%;
}

.px-griditem {
  font-size: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.qt-loading {
  position: relative;
  width: 3em;
  height: 3em;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: 0;
}
/* qtload001 */
#qt-loading-qtload001 > div {
  position: relative;
  width: 3em;
  height: 3em;
  border: 4px solid;
  border-top-color: transparent;
  border-right-color: rgb(34 160 204 / 22%);
  border-bottom-color: rgb(34 160 204 / 22%);
  border-left-color: rgb(34 160 204 / 22%);
  border-radius: 50%;
  animation: qt-loadanim001 1s infinite linear;
}
/* qtload002 */
#qt-loading-qtload002 > div {
  position: relative;
  width: 3em;
  height: 3em;
  border: 4px solid;
  border-top-color: rgb(0 169 224);
  border-right-color: rgb(34 160 204 / 22%);
  border-bottom-color: rgb(34 160 204 / 22%);
  border-left-color: rgb(34 160 204 / 22%);
  border-radius: 50%;
  animation: qt-loadanim001 1s infinite linear;
}
/* qtload003 */
#qt-loading-qtload003 > div {
  position: relative;
  width: 3em;
  height: 3em;
  border: 4px solid;
  border-top-color: transparent;
  border-right-color: rgb(34 160 204 / 22%);
  border-bottom-color: transparent;
  border-left-color: rgb(34 160 204 / 22%);
  border-radius: 50%;
  animation: qt-loadanim001 1s infinite linear;
}
/* qtload004 */
#qt-loading-qtload004 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#qt-loading-qtload004 > div {
  position: relative;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: rgb(34 160 204 / 22%);
  opacity: 0;
  animation: qt-loadanim003 2s infinite linear;
}
#qt-loading-qtload004 > div:nth-child(2) {
  animation-delay: 0.5s;
}
#qt-loading-qtload004 > div:nth-child(3) {
  animation-delay: 1s;
}
/* qtload005 */
#qt-loading-qtload005 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#qt-loading-qtload005 > div {
  position: relative;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: rgb(34 160 204 / 22%);
  animation: qt-loadanim005 2s infinite linear;
}
#qt-loading-qtload005 > div:nth-child(2) {
  animation-delay: 0.3s;
}
#qt-loading-qtload005 > div:nth-child(3) {
  animation-delay: 0.6s;
}
/* qtload006 */
#qt-loading-qtload006 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#qt-loading-qtload006 > div {
  position: relative;
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: rgb(34 160 204 / 22%);
  animation: qt-loadanim006 2s infinite steps(1, end);
}
#qt-loading-qtload006 > div:nth-child(2) {
  animation-delay: 0.3s;
}
#qt-loading-qtload006 > div:nth-child(3) {
  animation-delay: 0.6s;
}
/* qtload007 */
#qt-loading-qtload007 {
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#qt-loading-qtload007 > div {
  position: relative;
  width: 0.3em;
  height: 1.6em;
  border-radius: 0.15em;
  background-color: rgb(34 160 204 / 22%);
  animation: qt-loadanim005 2s infinite linear;
}
#qt-loading-qtload007 > div:nth-child(2) {
  animation-delay: 0.3s;
}
#qt-loading-qtload007 > div:nth-child(3) {
  animation-delay: 0.6s;
}
/* qtload008 */
#qt-loading-qtload008 {
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#qt-loading-qtload008 > div {
  position: relative;
  width: 0.3em;
  height: 1.6em;
  border-radius: 0.15em;
  background-color: rgb(34 160 204 / 22%);
  animation: qt-loadanim007 2s infinite linear;
}
#qt-loading-qtload008 > div:nth-child(2) {
  animation-delay: 0.3s;
}
#qt-loading-qtload008 > div:nth-child(3) {
  animation-delay: 0.6s;
}
/* qtload009 */
#qt-loading-qtload009 {
  width: 2.3em;
  height: 2.3em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#qt-loading-qtload009 > div {
  position: relative;
  width: 0.3em;
  height: 1.6em;
  border-radius: 0.15em;
  background-color: rgb(34 160 204 / 22%);
  animation: qt-loadanim006 2s infinite steps(1, end);
}
#qt-loading-qtload009 > div:nth-child(2) {
  animation-delay: 0.3s;
}
#qt-loading-qtload009 > div:nth-child(3) {
  animation-delay: 0.6s;
}
/* qtload010 */
#qt-loading-qtload010 > div:nth-child(1) {
  position: relative;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  background-color: rgb(34 160 204 / 22%);
  opacity: 0;
  animation: qt-loadanim004 2s infinite ease-out;
}
#qt-loading-qtload010 > div:nth-child(2) {
  position: absolute;
  width: 3em;
  height: 3em;
  left: 0;
  top: 0;
  border-radius: 50%;
  background-color: rgb(34 160 204 / 22%);
  opacity: 0;
  animation: qt-loadanim004 2s infinite ease-out;
  animation-delay: 1s;
}
/* qtload011 */
#qt-loading-qtload011 {
  width: 2em;
  height: 2em;
  background-color: rgba(211, 214, 222, 0.1);
}
#qt-loading-qtload011 > div {
  position: relative;
  width: 1em;
  height: 1em;
  background-color: rgb(34 160 204 / 22%);
  animation: qt-loadanim002 2s infinite linear;
}
/* qtload012 */
#qt-loading-qtload012 > div {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  animation: qt-loadanim008 2s infinite ease;
}
#qt-loading-qtload012 > div > div {
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  background-color: rgb(34 160 204 / 22%);
  animation: qt-loadanim009 2s infinite ease;
}
@keyframes qt-loadanim001 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes qt-loadanim002 {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  15% {
    transform: translate(1em, 0) rotate(90deg);
  }
  25% {
    transform: translate(1em, 0) rotate(90deg);
  }
  40% {
    transform: translate(1em, 1em) rotate(90deg);
  }
  50% {
    transform: translate(1em, 1em) rotate(90deg);
  }
  65% {
    transform: translate(0, 1em) rotate(0deg);
  }
  75% {
    transform: translate(0, 1em) rotate(0deg);
  }
  90% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
@keyframes qt-loadanim003 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
@keyframes qt-loadanim004 {
  0% {
    transform: scale(0%, 0%);
    opacity: 1;
  }
  100% {
    transform: scale(100%, 100%);
    opacity: 0;
  }
}
@keyframes qt-loadanim005 {
  0% {
    transform: translateY(0%);
  }
  20% {
    transform: translateY(-50%);
  }
  40% {
    transform: translateY(0%);
  }
}
@keyframes qt-loadanim006 {
  0% {
    background-color: rgb(34 160 204 / 22%);
  }
  20% {
    background-color: rgb(0 169 224);
  }
  40% {
    background-color: rgb(34 160 204 / 22%);
  }
}
@keyframes qt-loadanim007 {
  0% {
    transform: scaleY(100%);
  }
  20% {
    transform: scaleY(200%);
  }
  40% {
    transform: scaleY(100%);
  }
}
@keyframes qt-loadanim008 {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(180deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes qt-loadanim009 {
  0% {
    transform: scale(0%);
  }
  25% {
    transform: scale(100%);
  }
  50% {
    transform: scale(0%);
  }
  100% {
    transform: scale(0%);
  }
}
/* loader masood end  */

@media (min-height: 960px) {
  .move-up {
    height: 31vh;
  }

  .edu-cen {
    width: 31vh;
    right: -11vw;
    bottom: 12vh;
  }
}
@media (min-height: 1200px) {
  .move-up {
    height: 31vh;
  }

  .edu-cen {
    width: 31vh;
    right: -11vw;
    bottom: 12.5vh;
  }
}

@media (min-height: 1440px) {
  .edu-cen {
    bottom: 13.2vh;
  }
}
@media (min-width: 1200px) and (max-width: 1800px) {
  .move-up ul li {
    width: 49.75%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .move-up ul li {
    width: 49.74%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .move-up ul li {
    width: 49.6%;
  }
  .edu-cen {
    right: -183px;
  }
  .move-up ul li h4 {
    font-size: 20px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .move-up ul li {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .move-up ul li:first-child {
    border-right: 0;
    border-bottom: 1px solid #cccccc;
  }
  .move-up ul {
    margin-top: 50px;
  }

  .menu-box ul li a {
    padding: 10px;
    font-size: 13px;
  }

  .move-up ul li h4 {
    font-size: 20px;
  }

  .move-up ul li .round-red-btn {
    margin: 0 auto;
    margin-top: 7px;
    padding: 5px 25px;
  }

  .move-up {
    height: auto;
    width: 100%;
    padding-top: 1px;
  }
  .edu-cen {
    right: 0;
    transform: none;
    top: 0;
    width: 100%;
    bottom: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    font-size: 20px;
    border-radius: 10px 10px 0px 0px;
  }
}
