.review-que {
  background-color: white;
  background-image: none;
  box-shadow: 0px 0px 14px 0px #92929254;
  border-radius: 10px;
  padding: 40px;
  height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.review-que button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.review-que button:disabled:hover {
  background: #e38c67;
}

.review-que button:disabled,
.review-que [type="button"]:disabled,
.review-que [type="reset"]:disabled,
.review-que [type="submit"]:disabled {
  opacity: 0.4;
  pointer-events: none;
  margin-top: 0px;
  cursor: not-allowed;
}

.review-que button:not(:disabled) .spinner-border-sm,
.review-que [type="button"]:not(:disabled) .spinner-border-sm,
.review-que [type="reset"]:not(:disabled) .spinner-border-sm,
.review-que [type="submit"]:not(:disabled) .spinner-border-sm {
  display: none;
}
.review-que button:disabled,
.review-que [type="button"]:disabled .spinner-border-sm,
.review-que [type="reset"]:disabled .spinner-border-sm,
.review-que [type="submit"]:disabled .spinner-border-sm {
  display: inline-block;
  margin-right: 5px;
}

.review-que h3 {
  font-family: "Oswald", sans-serif;
  color: #000;
  font-size: 28px;
  margin-bottom: 30px;
  font-weight: 300;
}

.review-que h3 span {
  color: #e38c67;
  font-weight: 700;
}
.radio-custom {
  opacity: 0;
  position: absolute;
}
.radio-custom,
.radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 0px;
  cursor: pointer;
}
.radio-custom-label {
  position: relative;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  display: block;
}

.radio-custom + .radio-custom-label:before {
  content: "";
  background-color: #fff;
  border: 2px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  margin-right: 10px;
  text-align: center;
  margin-top: -2px;
}

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}
.radio-custom + .radio-custom-label.is-danger:before {
  content: "";
  border: 2px solid red;
}

.radio-custom:checked + .radio-custom-label:before {
  /*background-image: url('../images/tick.svg');*/
  /*background-image: url('../images/tick.png');
    background-position: center;
    background-repeat: no-repeat;*/
  background-repeat: no-repeat;
  background-color: #00a9e0;
  border: 2px solid #ffffff;
  outline: 2px solid #00a9e0;
  box-shadow: 0px 0px 8px 1px #00a9e0;
}
.review-que .round-red-btn {
  padding: 7px 35px;
  color: white;
  font-size: 17px;
  margin-top: 0px;
  border: 0;
}
.review-que .invalid-feedback,
.review-que p.help.is-danger {
  font-size: 18px;
  margin-top: -12px;
  color: red;
  font-weight: 400;
  font-family: "Sarabun", sans-serif;
  margin-bottom: 15px;
}

@media (min-height: 960px) {
  .review-que {
    height: 70vh;
  }
}
@media (min-height: 1200px) {
  .review-que {
    height: 75vh;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .radio-custom + .radio-custom-label:before {
    position: absolute;
    left: 0;
    top: 4px;
  }
  .radio-custom-label {
    padding-left: 35px;
  }
  .review-que h3 {
    font-size: 26px;
  }

  .review-que {
    height: auto;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .radio-custom-label {
    margin-bottom: 15px;
    font-size: 14px;
    padding-left: 27px;
  }
  .radio-custom + .radio-custom-label:before {
    content: "";
    background-color: #fff;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    width: 17px;
    height: 17px;
    margin-right: 3px;
    text-align: center;
    margin-top: -1px;
  }
  .radio-custom + .radio-custom-label:before {
    position: absolute;
    left: 0;
    top: 4px;
  }
  .review-que .round-red-btn {
    padding: 5px 25px;
    color: white;
    font-size: 14px;
    margin-top: 10px;
  }

  .review-que h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .review-que {
    height: auto;
    padding: 40px 20px;
  }
}
