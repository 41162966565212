.terms-box h3 {
  font-family: "Oswald", sans-serif;
  color: #000;
  font-size: 30px;
  /*margin-bottom: 0px;*/
  font-weight: 300;
}
.terms-box .circle-list {
  margin-bottom: 10px;
}
.terms-box .circle-list LI {
  font-size: 14px;
  margin-bottom: 2px;
  padding-left: 20px;
}

.terms-box .circle-list LI::before {
  position: absolute;
  left: 0;
  top: 6px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #e38c67;
  content: "";
}

.terms-box h4 {
  line-height: 20px;
  color: #000;
  font-weight: 700;
  font-family: "Sarabun", sans-serif;
  font-size: 18px;
  margin-bottom: 5px;
  padding-top: 13px;
  border-top: 1px solid #dddddd;
}

.terms-box h4:first-child {
  padding-top: 0px;
  border-top: 0px solid #dddddd;
}

.terms-box p {
  line-height: 20px;
  color: #000;
  font-size: 15px;
  font-weight: 400;
  font-family: "Sarabun", sans-serif;
}

.terms-box p a {
  text-decoration: none;
  color: #e38c67;
}
@media (min-width: 320px) and (max-width: 767px) {
  .terms-box h3 {
    font-size: 22px;
  }
  .terms-box .circle-list {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .terms-box .circle-list LI::before {
    top: 3px;
  }

  .terms-box p {
    line-height: 17px;
    font-size: 14px;
  }

  .terms-box h4 {
    font-size: 16px;
  }
}
