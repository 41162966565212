.knwg-enhnc h5 {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  color: #00000f;
  font-size: 25px;
  text-align: center;
  margin-top: 28px;
  margin-bottom: 4px;
}

.ench-div {
  background-color: white;
  box-shadow: 0px 0px 14px #92929238;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  border-radius: 7px;
  position: relative;
  cursor: pointer;
  transition: all 0.4s;
}
.ench-div img {
  margin-right: 25px;
}

.ench-div img.completed-level {
  margin-right: 0px;
  margin-left: auto;
  width: 45px;
}
.menu-box ul li a .completed-inner-level {
  position: absolute;
  margin-right: 0;
  right: 13px;
  width: 28px;
  top: 12px;
}

.ench-div span {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  display: block;
  color: #000000;
  font-size: 17px;
  transition: all 0.4s;
}
.ench-div a {
  color: #000000;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
}

.ench-div p {
  color: #000000;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  text-decoration: none;
  margin: 0;
  transition: all 0.4s;
}
.ench-div.active p,
.ench-div.active span,
.ench-div:hover p,
.ench-div:hover span {
  color: #fff;
  transition: all 0.4s;
}
.ench-div:hover,
.ench-div.active {
  background-color: #00a9e0;
  transition: all 0.4s;
}
.ench-div:hover img.completed-level,
.ench-div.active img.completed-level {
  filter: brightness(0) invert(1);
}

.red-box {
  background-color: #00a9e0;
  padding: 20px;
  color: #ffffff !important;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-top: 15px;
  border-radius: 7px;
  justify-content: center;
  text-align: center;
  height: calc(100% - 30px);
  flex-direction: column;
  text-decoration: none;
  transition: all 0.4s;
}

.red-box:hover {
  transition: all 0.4s;
  background: #e38c67;
}

.red-box span {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
  display: block;
  font-size: 20px;
}
.knwg-enhnc {
  position: relative;
}
.knwg-enhnc .wel-shp {
  position: absolute;
  left: 0;
  top: 16px;
  z-index: -1;
}

.menu-box {
  position: absolute;
  bottom: calc(100% + 10px);
  box-shadow: 0px 0px 14px #92929238;
  left: 0;
  width: 1025px;
  z-index: 11111;
  display: none;
  background-color: white;
}

.menu-box ul {
  padding: 0;
  margin: 0;
}

.menu-box ul li {
  width: 50%;
  float: left;
  list-style: none;
}

.menu-box ul.odd li:last-child {
  width: 100%;
}

.menu-box ul li a {
  padding: 15px;
  position: relative;
  display: block;
  border-bottom: 1px solid #cccccc;
  transition: all 0.4s;
}

.menu-box ul li a span {
  display: inline-block;
  font-size: 14px;
  margin-right: 5px;
  color: #00a9e0 !important;
}

.menu-box ul li a:hover {
  color: #ffffff;
  transition: all 0.4s;
  background-color: #00a9e0;
}
.menu-box ul li a:hover span {
  color: #ffffff !important;
  transition: all 0.4s;
}
.menu-box ul li a:hover img.completed-inner-level {
  filter: brightness(0) invert(1);
}

.menu-box ul li:nth-child(odd) a {
  border-right: 1px solid #cccccc;
}

.menu-box ul li.w-100.not-eligible strong {
  padding: 10px;
  font-size: 16px;
  display: block;
  text-align: center;
  color: red;
}

#Sophomore {
  left: calc(-98.333333% - 30px);
}

#Junior {
  left: calc(-196.666666% - 60px);
}

#Senior {
  left: calc(-44.333333% - 30px);
}
@media (min-height: 960px) {
  .ench-div,
  .red-box {
    height: 10vh;
  }
}

@media (min-height: 1200px) {
  .ench-div,
  .red-box {
    height: 10vh;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .menu-box {
    width: 315%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .menu-box {
    left: 0 !important;
    width: 100% !important;
  }

  .menu-box ul li a {
    padding: 10px;
    font-size: 13px;
  }
  .menu-box ul li a .completed-inner-level {
    right: 13px;
    width: 22px;
    top: 10px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .menu-box {
    left: 0 !important;
    width: 100% !important;
  }
  .menu-box ul li:nth-child(odd) a {
    border-right: 0px solid #cccccc;
  }

  .menu-box ul li a .completed-inner-level {
    width: 20px;
    top: 11px;
  }

  .menu-box ul li a span {
    font-size: 13px;
  }
  .menu-box ul li {
    width: 100%;
  }
  .knwg-enhnc h5 {
    font-size: 22px;
  }
  .ench-div img {
    margin-right: 10px;
  }
}
