.content-div {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 85px;
  padding-right: 20px;
}
.content-div h3 {
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 20px;
}
.content-div p {
  color: #ffffff;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 30px;
}
.slide-secnd .content-div h3 {
  color: #000;
  font-size: 36px;
  margin-bottom: 30px;
  font-weight: 300;
  padding-right: 40px;
}
.back-slid.slide-secnd::after {
  content: none;
}
.m01s-img {
  max-width: 535px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
}
img.image-area.img-fluid {
  width: 100%;
}
.grayton_marg-img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  width: 75%;
  margin-top: 15px;
}
.m01s-img img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
img.img-responsive.center-block.mar-tp-bt {
  max-width: 100%;
  margin: 0 auto;
  display: block;
}
img.red-cls {
  width: 14px;
  margin: 0 auto;
  display: block;
}

button.btn.btn-img {
  padding: 0;
  position: relative;
}
button.btn.btn-img .overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.45);
  width: 100%;
  height: 100%;
  transform: scale(0);
  transition: ease-in-out 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
button.btn.btn-img:hover .overlay {
  transform: scale(1);
}
button.btn.btn-img .overlay img {
  width: 50px;
}
button.btn.btn-img:focus {
  box-shadow: none;
}
.btn-img-model .modal-dialog {
  max-width: fit-content;
}

.row.h-auto img.img-responsive.center-block {
  margin: 0 auto;
  display: block;
  margin-top: 15px;
  max-width: 100%;
}
img.img-responsive {
  max-width: 100%;
}
.image-area.img-wrapper {
  margin-top: 15px;
}

.m01s-img::after {
  content: "";
  background-color: #00a9e0;
  width: 55%;
  top: -20px;
  right: 10px;
  bottom: 65px;
  position: absolute;
  z-index: -1;
}
.back-slid.slide-secnd {
  height: 478px;
  background-color: white;
  background-image: none;
  box-shadow: 0px 0px 14px 0px #92929254;
  border-radius: 10px;
}
.back-slid.slide-secnd.large-img {
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
}
.thank-bg {
  background-image: url(../../../../../public/images/slide-thanku-bg.jpg) !important;
}

.slide-secnd .content-div h3 {
  font-size: 28px;
}
.slide-secnd .content-div p {
  color: #000000;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 20px;
}

.video-wrap {
  width: 100%;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.video-iframe {
  width: auto;
  height: 100%;
}

.list-update-design {
  margin-top: 15px;
  list-style: none;
  padding-left: 0px;
}

.list-update-design li {
  border: 1px solid #ccc;
  padding: 10px 10px;
  margin-bottom: 20px;
  position: relative;
  background: #d77d6d;
  color: #fff;
}
.list-update-design li:before {
  content: "";
  position: absolute;
  left: -32px;
  color: #d77d6d;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-right: 40px solid #d77d6d;
  border-bottom: 30px solid transparent;
  top: 19px;
}
.list-update-design li h3 {
  font-size: 24px;
}
.list-update-design li h3,
.list-update-design li p {
  margin-bottom: 10px !important;
  color: #fff !important;
}
.list-update-design li p {
  font-size: 14px !important;
  line-height: 20px !important;
}
.ashly-series-10-outer {
  position: relative;
}

img.series-10 {
  width: 80%;
  display: block;
  margin: 0 auto 20px auto;
}

.ashly-info-one {
  cursor: pointer !important;
  font-family: FontAwesome;
  background-color: white;
  border-radius: 50%;
  border: 4px solid rgb(222, 222, 222);
  display: block;
  padding: 0px;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 15px;
  color: #fff;
  cursor: move;
  right: 0px;
  left: 0px;
  margin: 10px auto;
}
.ashly-info-one img {
  width: 100%;
  height: 100%;
}
span#se-10-info-1 {
  position: absolute;
  top: 15%;
  z-index: 2;
  left: 0%;
}
span#se-10-info-2 {
  position: absolute;
  top: 24%;
  left: 30%;
}
span#se-10-info-3 {
  position: absolute;
  top: 29%;
  left: 43%;
}
span#se-10-info-4 {
  position: absolute;
  top: 42%;
  left: 26%;
}
span#se-8-info-1 {
  position: absolute;
  top: 17%;
  left: 6%;
}

span#se-8-info-2 {
  position: absolute;
  top: 28%;
  left: 0%;
}
span#se-8-info-3 {
  position: absolute;
  top: 40%;
  left: 26%;
}

.popover-header {
  padding: 8px 12px;
  background-color: #dd8170;
  border-bottom: 1px solid #dd8170;
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  border-radius: 0px;
  color: #fff;
  font-size: 14px;
  border: none;
}
.popover-body {
  padding: 9px 14px;
  background: #a45a4d;
  font-family: lato;
  color: #fff;
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #a45a4d;
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #a45a4d;
}

.popover {
  z-index: -10;
  display: none;
}
div#popovers-slide .popover {
  z-index: 1070;
  display: block;
}

@media (min-height: 960px) {
  .back-slid.slide-secnd,
  .back-slid.slide-secnd.large-img {
    height: 70vh;
  }
  .m01s-img img {
    width: 89%;
  }
}
@media (min-height: 1200px) {
  .back-slid.slide-secnd,
  .back-slid.slide-secnd.large-img {
    height: 75vh;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .content-div p {
    margin-bottom: 15px;
    font-size: 26px;
  }
  .back-slid.slide-secnd,
  .back-slid.slide-secnd.large-img {
    height: auto;
  }

  .video-iframe {
    height: 365px;
  }

  .content-div h3 {
    font-size: 32px;
    margin-bottom: 5px;
  }
  .thank-bg .content-div {
    padding-left: 30px;
    padding-right: 30px;
  }
  .col-lg-4.inc-z.d-flex img.img-fluid.w-75.m-auto.me-lg-0 {
    width: 50% !important;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .lide-secnds .content-div h3 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 300;
    padding-right: 0;
  }
  .col-lg-4.inc-z.d-flex img.img-fluid.w-75.m-auto.me-lg-0 {
    width: 50% !important;
    margin-bottom: 15px !important;
  }
  .content-div {
    padding-left: 20px;
    padding-right: 20px;
  }

  .slide-secnd .content-div h3 {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 300;
    padding-right: 0;
  }

  .content-div h3 {
    font-size: 22px;
    margin-bottom: 0px;
  }

  .video-iframe {
    height: 184px;
  }
  .video-wrap {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .content-div p {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 22px;
  }

  .back-slid.slide-secnd,
  .back-slid.slide-secnd.large-img {
    height: auto;
  }
  .back-slid.slide-secnd.large-img {
    padding-top: 70px;
  }
  .m01s-img img {
    display: block;
    margin: 0 auto;
    width: 82%;
  }

  .back-slid.thank-bg {
    padding-top: 40px;
  }
  .m01s-img {
    position: relative;
    max-width: 535px;
    margin: 0 auto;
    margin-top: 45px;
    margin-bottom: 0px;
  }
}
