.forgt-slide {
  background-image: url("../../../../public/images/forgot-bg.jpg") !important;
}

form.forgot-frm {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding-left: 50px;
}
.forgot-fild {
  position: relative;
}

form.forgot-frm label.form-label {
  color: #ffffff;
  font-family: "Sarabun", sans-serif;
  font-weight: 500;
  font-size: 18px;
}

form.forgot-frm .forgot-fild .form-control {
  background-color: transparent;
  height: 50px;
  border: 1px solid #ffffff;
  border-radius: 76px;
  color: #fff;
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-right: 205px;
}

form.forgot-frm .forgot-fild .form-control:focus {
  box-shadow: none;
  outline: none;
}

form.forgot-frm .forgot-fild .form-control::-webkit-input-placeholder {
  /* Edge */
  color: #fff;
}

form.forgot-frm .forgot-fild .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

form.forgot-frm .forgot-fild .form-control::placeholder {
  color: #fff;
}

form.forgot-frm .forgot-fild input[type="submit"] {
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;
  width: auto;
  border-radius: 76px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  color: #e38c67;
  border: 0;
  outline: none;
  transition: all 0.4s;
  font-size: 16px;
  font-family: "Sarabun", sans-serif;
  font-weight: 400;
}

form.forgot-frm .round-red-btn {
  font-size: 16px;
}

form.forgot-frm .forgot-fild input[type="submit"]:hover {
  background-color: #e38c67;
  color: #fff;
  transition: all 0.4s;
}

form.forgot-frm .row {
  height: auto;
}

form.forgot-frm .forgot-fild input.form-control.is-danger {
  color: red;
  border-color: red;
}

form.forgot-frm
  .forgot-fild
  input.form-control.is-danger::-webkit-input-placeholder {
  /* Edge */
  color: red;
}
form.forgot-frm
  .forgot-fild
  input.form-control.is-danger:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}
form.forgot-frm .forgot-fild input.form-control.is-danger::placeholder {
  color: red;
}

@media (min-width: 768px) and (max-width: 992px) {
  form.forgot-frm {
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 15px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .back-slid.forgt-slide {
    padding-top: 40px;
  }
  form.forgot-frm {
    padding-left: 20px;
    padding-right: 20px;
  }

  form.forgot-frm label.form-label {
    font-size: 16px;
  }
  form.forgot-frm .forgot-fild input[type="submit"] {
    position: absolute;
    right: 4px;
    top: 4px;
    bottom: 4px;
    width: auto;
    border-radius: 76px;
    padding: 0px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #fff;
    color: #e38c67;
    border: 0;
    outline: none;
    transition: all 0.4s;
    font-size: 12px;
    font-family: "Sarabun", sans-serif;
    font-weight: 400;
  }

  form.forgot-frm .forgot-fild .form-control {
    padding-right: 150px;
    height: 40px;
  }

  form.forgot-frm .round-red-btn {
    font-size: 14px;
  }
  .chek-term a {
    display: inline-block;
  }
}
