/* diploma css start here  */

.diploma {
  background: #fff;
  font-size: 14px;
  color: #716e6e;
  font-weight: 300;
  line-height: 22px;
  font-family: "Raleway", sans-serif;
  height: 100%;
  width: 1170px;
  margin: 0 auto;
}
.diploma input,
.diploma button {
  border-radius: 0 !important;
  box-shadow: none !important;
}
.diploma a {
  color: #000;
  text-decoration: none;
}
.diploma a:hover {
  color: #000;
  text-decoration: underline;
}
.diploma h1 {
  color: #000;
  font-weight: 500;
}
.diploma h2,
.diploma h3,
.diploma h4,
.diploma h5,
.diploma h6 {
  color: #000;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
}
.diploma .glyphicon-search:before {
  content: "\e003";
  font-family: "Glyphicons Halflings";
}
.diploma select {
  outline: none !important;
}
.diploma h1,
.diploma h2,
.diploma h3,
.diploma h4,
.diploma h5,
.diploma h6,
.diploma p {
  margin-top: 0;
  padding: 0px;
  margin-right: 0;
  margin-bottom: 20px;
  margin-left: 0;
}
.diploma p {
  line-height: 20px;
  color: #000;
  font-size: 16px;
}
.diploma p:last-child {
  margin-bottom: 0px;
}
.diploma h1 {
  font-size: 30px;
}
.diploma h2 {
  font-size: 26px;
}
.diploma h3 {
  font-size: 24px;
}
.diploma h4 {
  font-size: 20px;
}
.diploma h5 {
  font-size: 18px;
}
.diploma h6 {
  font-size: 16px;
}
.diploma .clear:after {
  content: "";
  width: 100%;
  clear: both;
  display: table;
}
.diploma div#searchshow {
  position: relative;
}
.diploma .bg {
  position: relative;
  padding: 170px;
  height: 1140px;
  width: 1170px;
}

.priv-btn {
  padding: 15px 0;
}

.diploma button.print-btn {
  margin: 0 auto;
  padding: 10px 28px;
  color: white;
  font-size: 18px;
  font-weight: 400;
  background: #e38c67;
  display: flex;
  justify-content: center;
  width: fit-content;
  border-radius: 30px !important;
  text-decoration: none;
  align-items: center;
  transition: all 0.4s;
  border: 0;
  outline: none;
}
.diploma button.print-btn img {
  width: 20px;
  margin-right: 5px;
}
.diploma button.print-btn:hover {
  background: #00a9e0;
  color: #fff !important;
}
.diploma .bg img.main-bg {
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  top: 0px;
}
.diploma .content-outer {
  position: relative;
}
.diploma .bg h1 {
  font-family: "beckettregular";
  text-align: center;
  font-size: 62px;
}
.diploma .bg h2 {
  text-align: center;
  margin: 30px 0px 6px 0px;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 20px;
  color: #414141;
  line-height: 26px;
}
.diploma .dec img {
  margin: 0 auto;
  display: block;
}
.diploma .left-input {
  float: left;
  width: 32%;
}

.diploma .stamp {
  float: left;
  width: 36%;
}
.diploma .right-input {
  float: left;
  width: 29%;
  text-align: center;
}
.diploma .part {
  font-family: "birds_of_paradiseregular";
}
.diploma .part {
  text-align: center;
  color: #000;
  display: block;
  font-size: 60px;
  border-bottom: 1px solid #000;
  padding-bottom: 33px;
  width: 100%;
  margin: 57px auto 0px auto;
  text-transform: capitalize;
}
.diploma .footer-area img {
  margin: 20px auto;
  display: block;
}
.diploma .bg p {
  text-align: center;
  line-height: 30px;
}
.diploma .bg p b {
  font-weight: 700;
}
.diploma .footer-area {
  height: 160px;
  margin: 0 auto;
  width: 100%;
}
.diploma .footer-area input {
  background: none;
  border: 1px solid #000;
  border-width: 0px 0px 1px 0px;
  width: 239px;
  outline: none;
  margin-top: 56px;
  text-align: center;
}
.diploma .footer-area label {
  font-size: 16px;
  margin: 10px 0px;
  color: #000;
  width: 221px;
  text-align: center;
  font-weight: 700;
}
.diploma #printableArea {
  height: 1140px !important;
  width: 1170px;
  max-width: fit-content;
}
.diploma .alert-info {
  margin-top: 10px;
  margin-left: 25px;
  width: 1142px;
  display: none;
}

.diploma .right-input img {
  width: 158px;
  margin: 0 auto;
}
.signature-holder {
  border: 1px solid #000;
  border-width: 0px 0px 1px 0px;
}

/* diploma css end here  */
