.not-pass {
  color: #e38c67;
  font-size: 20px;
  font-weight: 400;
  font-family: "Oswald", sans-serif;
  margin-bottom: 10px;
}

.rzl-fa {
  width: 80%;
  margin: 0 auto;
  display: block;
}

.rzl-pa {
  width: 95%;
  margin: 0 auto;
  display: block;
}
.rezlt-bton {
  margin-top: 10px !important;
}
@media (min-width: 768px) and (max-width: 992px) {
  .rzl-pa,
  .rzl-fa {
    width: 50%;
    margin: 0 auto;
    display: block;
    margin-bottom: 20px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .not-pass {
    color: #e38c67;
    font-size: 16px;
    line-height: 1.2;
  }

  .rzl-pa,
  .rzl-fa {
    width: 75%;
    margin: 0 auto;
    display: block;
    margin-bottom: 15px;
  }
}
