.ban-cont {
  margin-top: 20px;
}
.txt-in {
  margin-top: 20px;
}
.main-heading {
  color: #000000;
  font-family: "Oswald", sans-serif;
  font-size: 35px;
  font-weight: 300;
  margin-bottom: 5px;
}
.main-heading span {
  font-weight: 700;
  text-transform: uppercase;
}
span.gen-txt {
  display: block;
  color: #000000;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.owl-theme .owl-dots .owl-dot span {
  background: #25addc66 !important;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #e0876c !important;
}

.ban-cont .round-red-btn {
  margin-top: 15px;
}
.owl-theme.centered .owl-item {
  opacity: 0.7;
}
.owl-item.active.center {
  opacity: 1;
}

@media (min-height: 960px) {
  .main-ban {
    height: 45vh;
  }

  .ban-cont {
    margin-top: 20px;
    height: 21vh;

    width: 100%;
    justify-content: center;
  }

  .ban-cont .row {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

@media (min-height: 1200px) {
  .main-ban {
    height: 45vh;
  }
  .ban-cont {
    margin-top: 20px;
    height: 28vh;

    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .ban-cont {
    text-align: center;
    margin-top: 0px;
  }

  .txt-in {
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .ban-cont .round-red-btn {
    margin: 0 auto;
    margin-top: 15px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .ban-cont {
    text-align: center;
    margin-top: 0px;
  }

  .ban-cont .round-red-btn {
    margin: 0 auto;
    margin-top: 15px;
  }
  .txt-in {
    margin-top: 0px;
    margin-bottom: 15px;
  }
  .main-heading {
    color: #000000;
    font-family: "Oswald", sans-serif;
    font-size: 24px;
  }
}
